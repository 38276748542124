import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FC } from "react";
import { Database, useRestore } from "src/Databases";
import { DashboardContext } from "src/Dashboard";

interface RestoreProps {
    database: Database;
    context: DashboardContext;
    useOpen: [ boolean, (open: boolean) => void ];
}

export const Restore: FC<RestoreProps> = ({ database: db, useOpen: [ open, setOpen], context }) => {
    const restore = useRestore(db.id, context);

    const handleRestore = () => {
        setOpen(false);
        restore();
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Restore Database</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to restore the deleted database?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={handleRestore}>Restore</Button>
            </DialogActions>
        </Dialog>
    );
};
