import { ActionMenu } from "src/Databases/ActionMenu";
import { Databases } from "src/Databases/Databases";
import { DatabaseRow } from "src/Databases/DatabaseRow";
import { Details } from "src/Databases/Details";
import { UUIDList } from "src/Databases/UUIDList";
import { Delete } from "src/Databases/Delete";
import { Restore } from "src/Databases/Restore";
import { DatabasesMenu } from "src/Databases/DatabasesMenu";
import { AddDatabase } from "src/Databases/AddDatabase";
import { DatabasesTableHead } from "src/Databases/DatabasesTableHead";

import { useDatabases } from "src/Databases/useDatabases";
import { useRefresh } from "src/Databases/useRefresh";
import { useDelete } from "src/Databases/useDelete";
import { useRestore } from "src/Databases/useRestore";
import { useAdd } from "src/Databases/useAdd";
import { useExport } from "src/Databases/useExport";

export interface Database {
    id: string;
    name: string;
    type: number;
    raveUrl: string;
    version: string;
    sqlServerId: string;
    sqlServerName: string;
    sqlServerAlias: string;
    isClassic: boolean;
    status: number;
    connectionString: string;
    created: Date,
    updated: Date,
    studyGroupUuids: string[];
    studyUuids: string[];
}

export interface User {
    initials?: string;
    name?: string;
    email?: string;
    isAdmin: boolean;
    powerUserGroup?: string;
}

export type Updater = [ boolean, (value: boolean) => void ];

export type Order = "asc" | "desc";

export const DatabaseTypes: string[] = [
    "None",
    "Study Group",
    "Study",
    "Study Group and Study"
];

export const DatabaseStatus: string[] = [
    "Online",
    "Deleted",
    "Offline"
];

export { useDatabases, useRefresh, useDelete, useRestore, useAdd, useExport };
export {
    Databases,
    DatabaseRow,
    ActionMenu,
    Details,
    UUIDList,
    Delete,
    Restore,
    DatabasesMenu,
    AddDatabase,
    DatabasesTableHead
};
