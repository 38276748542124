import { useCallback } from "react";
import { AppSettings } from "src/AppSettings";
import { DashboardContext } from "src/Dashboard";
import { useOktaAuth } from "@okta/okta-react";

export function useExport(context: DashboardContext): () => void {
  const { oktaAuth } = useOktaAuth();

  return useCallback(() => {
    const fetchExport = async () => {
      const token = oktaAuth.getAccessToken();
      try {
        const response = await fetch(
          `${AppSettings.apiHost}/api/v1/ui/databases/export`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              ...(token && { Authorization: `Bearer ${token}` }),
            },
          }
        );

        if (!response.ok)
          throw new Error(
            "An error occurred while exporting the database information. " +
              `The response status is ${response.status} - ${response.statusText}.`
          );

        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Databases.csv";
        document.body.appendChild(link);
        link.click();
      } catch (e: any) {
        context.setError({ active: true, message: e.message });
      }
    };

    fetchExport();
  }, [context, oktaAuth]);
}
