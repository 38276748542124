import { AppBar, Box, Button, InputBase, Toolbar, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { FC, useState } from "react";
import { UserMenu } from "src/UserMenu";
import {
    Thunderstorm as ThunderstormIcon,
    Search as SearchIcon } from "@mui/icons-material";
import { DashboardContext } from "src/Dashboard";


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: '400px',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '38ch',
    },
}));

const StyledButton = styled(Button)(( {theme} ) => ({    
    backgroundColor: theme.palette.common.white,
    color: "primary",  
    '&:hover': {
        backgroundColor: theme.palette.common.white,
    },
}));

interface TopBarProps {
    context: DashboardContext;
}

export const TopBar: FC<TopBarProps> = ({ context }) => {
    const setFilter = context.useFilter[1];
    const setStale = context.useStale[1];
    const [filterText, setFilterText] = useState('');

    const handleFilterChanged = (event: any) => {
        if (event.key === 'Enter') {
            handleSearchButtonClicked();
        }
    }

    const handleSearchButtonClicked = () => {
        setFilter(filterText);
        setStale(true);
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <ThunderstormIcon sx={{ display: { xs: "flex" }, mr: 1 }} />
                    <Typography variant="h6" component="div">
                        Storm UI
                    </Typography>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase type="search" placeholder="Search for UUIDs, versions, names, urls…" id="filter"
                            onKeyDown={handleFilterChanged} 
                            onChange={(event) => {setFilterText(event.target.value)}}/>
                    </Search>
                    <StyledButton variant="outlined" startIcon={<SearchIcon />}
                        onClick={handleSearchButtonClicked}>search</StyledButton>
                    <Box sx={{ flexGrow: 1 }} />
                    <UserMenu/>
                </Toolbar>
            </AppBar>
        </Box>
    );
};
