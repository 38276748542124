import { ChangeEvent, FC, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField} from "@mui/material";
import { DashboardContext } from "src/Dashboard";
import { useAdd } from "src/Databases";


interface AddDatabaseProps {
    context: DashboardContext;
    useOpen: [ boolean, (open: boolean) => void ];
}

export const AddDatabase: FC<AddDatabaseProps> = ({ useOpen: [ open, setOpen], context }) => {
    const [ studyGroupUuid, setStudyGroupUuid ] = useState("");
    const [ connectionString, setConnectionString ] = useState("");
    const add = useAdd(context);

    const handleStudyGroupUuidChanged = (event: ChangeEvent<HTMLInputElement>) =>
        setStudyGroupUuid(event.target.value);

    const handleConnectionStringChanged = (event: ChangeEvent<HTMLInputElement>) =>
        setConnectionString(event.target.value);

    const handleSave = () => {
        setOpen(false);
        add(studyGroupUuid, connectionString);
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Add Database</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom={2}>
                    Please enter below the Study Group UUID and Connection String with what your database is accessible.
                </DialogContentText>
                <TextField autoFocus margin="dense" id="studyGroupUuid" label="Study Group Uuid"
                    type="text" fullWidth variant="standard" onChange={handleStudyGroupUuidChanged} />
                <TextField margin="dense" id="connectionString" label="Connection String"
                    type="text" fullWidth variant="standard" onChange={handleConnectionStringChanged} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    )
};
