import { FC } from "react";
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { Database, Order } from "src/Databases";

interface HeadCell {
    id: keyof Database;
    label: string;
    align: "left" | "right" | "center" | "inherit";
}

const headCells: readonly HeadCell[] = [
    { id: "name", label: "Name", align: "center" },
    { id: "type", label: "Type", align: "center" },
    { id: "version", label: "Version", align: "center" },
    { id: "raveUrl", label: "RaveUrl", align: "left" },
    { id: "isClassic", label: "Classic", align: "center" }
];

interface DatabasesTableHeadProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Database) => void;
    order: Order;
    orderBy: string;
}

export const DatabasesTableHead: FC<DatabasesTableHeadProps> = ({ onRequestSort, order, orderBy }) => {
    const createSortHandler =
        (property: keyof Database) => (event: React.MouseEvent<unknown>) => onRequestSort(event, property);

    return (
        <TableHead>
            <TableRow>
                <TableCell />

                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.align}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}

                <TableCell align="center">Actions</TableCell>
            </TableRow>
        </TableHead>
    );
}
