import { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "src/App/Routes";
import { CssBaseline } from "@mui/material";

export type ErrorHandler = (message: string | null) => void;
export const App: FC = () => {
  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <Routes></Routes>
      </BrowserRouter>
    </>
  );
};
