import { useEffect, useState } from "react";
import { AppSettings } from "src/AppSettings";
import { Database } from "src/Databases";
import { dateReviver } from "src/Utils";
import { DashboardContext } from "src/Dashboard";
import { useOktaAuth } from "@okta/okta-react";

export function useDatabases(context: DashboardContext) {
  const [ stale, setStale ] = context.useStale;
  const [ filter ] = context.useFilter;
  const [ databases, setDatabases ] = useState<Database[]>([]);
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    const fetchDatabases = async () => {
      const token = oktaAuth.getIdToken();
      try {
        context.setLoading(true);

        const response = await fetch(
          `${AppSettings.apiHost}/api/v1/ui/databases?filter=${filter}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              ...(token && { Authorization: `Bearer ${token}` }),
            },
          }
        );

        if (!response.ok)
          throw new Error(
            "Could not acquire database data from the server. " +
              `The response status is ${response.status} - ${response.statusText}`
          );

        const content = await response.text();
        const databases = JSON.parse(content, dateReviver) as Database[];
        setDatabases(databases);
      } catch (e: any) {
        context.setError({ active: true, message: e.message });
      } finally {
        context.setLoading(false);
      }
    };

    if (stale)
      fetchDatabases();

    setStale(false);
  }, [context, stale, setStale, oktaAuth, filter]);

  return databases;
}
