import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet } from "react-router-dom";
import { Loading } from "src/Dashboard/Loading";
import { AppSettings } from "src/AppSettings";

export const RequiredAuth: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const isDevelopment =
    AppSettings.oktaOff ||
    !process.env.NODE_ENV || 
     process.env.NODE_ENV === "development" ;
  useEffect(() => {
    if (!authState || isDevelopment) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authState, authState?.isAuthenticated, isDevelopment]);

  if (!isDevelopment && (!authState || !authState?.isAuthenticated)) {
    return <Loading open />;
  }

  return <Outlet />;
};
