import { ChangeEvent, FC, useState } from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel
} from "@mui/material";
import { Database, useDelete } from "src/Databases";
import { DashboardContext } from "src/Dashboard";

interface DeleteProps {
    database: Database;
    context: DashboardContext;
    useOpen: [ boolean, (open: boolean) => void ];
}

export const Delete: FC<DeleteProps> = ({ database: db, useOpen: [ open, setOpen], context }) => {
    const [ remove, setRemove ] = useState(db.status === 1);
    const _delete = useDelete(db.id, context);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRemove(event.target.checked);
    };

    const handleDelete = () => {
        setOpen(false);
        _delete(remove);
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Delete Database</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to delete the database?</DialogContentText>
                <DialogContentText><FormControlLabel disabled={db.status === 1}
                    control={<Checkbox checked={remove} onChange={handleChange} />}
                    label="Completely remove the database" /></DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={handleDelete}>{remove ? "Remove" : "Delete"}</Button>
            </DialogActions>
        </Dialog>
    );
};
