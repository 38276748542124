import React, { FC } from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

interface UUIDListProps {
    title: string;
    uuids: string[];
}

export const UUIDList: FC<UUIDListProps> = ({ title, uuids }) => (
    <React.Fragment>
        <Typography variant="h6">
            {title}
        </Typography>
        <List>
            {uuids.map((item) => (
                <ListItem key={item} disablePadding>
                    <ListItemText primary={item}
                        primaryTypographyProps={{ fontSize: 12, fontFamily: "monospace" }} />
                </ListItem>
            ))}
        </List>
    </React.Fragment>
);
