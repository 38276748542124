import { FC, useState } from "react";
import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { Add as AddIcon, Save as SaveIcon } from "@mui/icons-material";
import { AddDatabase, User, useExport } from "src/Databases";
import { DashboardContext } from "src/Dashboard";

interface DatabasesMenuProps {
    context: DashboardContext;
    user: User;
}

export const DatabasesMenu: FC<DatabasesMenuProps> = ({ context, user }) => {
    const useAddDatabase = useState(false);
    const setAddOpen = useAddDatabase[1];
    const _export = useExport(context);

    return (
        <Box>
            <SpeedDial ariaLabel="Databases Menu"
                sx={{ position: "fixed", bottom: 48, right: 48 }} icon={<SpeedDialIcon />}>
                {user.isAdmin && <SpeedDialAction icon={<AddIcon />} tooltipTitle="Add Database" onClick={() => setAddOpen(true)} /> }
                <SpeedDialAction icon={<SaveIcon />} tooltipTitle="Export to CSV" onClick={() => _export()} />
            </SpeedDial>
            <AddDatabase useOpen={useAddDatabase} context={context} />
        </Box>
    );
};
