import React, { FC, useState } from "react";
import {
    Container,
    Alert,
    Stack,
    Snackbar,
    Paper
} from "@mui/material";

import { Loading } from "src/Dashboard/Loading";
import { TopBar } from "src/Dashboard/TopBar";
import { Databases, DatabasesMenu } from "src/Databases";
import { useOktaAuth } from "@okta/okta-react";
import { getUser } from "src/Helpers/common";

export interface ContextError {
    active: boolean;
    message: string | null;
}

export interface DashboardContext {
    setError: (error: ContextError) => void;
    setLoading: (visible: boolean) => void;
    useStale: [ stale: boolean, setStale: (stale: boolean) => void ];
    useFilter: [ filter: string, setFilter: (value: string) => void ];
}


export const Dashboard: FC = () => {
    const { authState } = useOktaAuth();
    const user = getUser(authState);
    const [ error, setError ] = useState<ContextError>({ active: false, message: null });
    const [ loading, setLoading ] = useState(false);
    const context: DashboardContext = { setError, setLoading, useStale: useState(true), useFilter: useState("") };

    return (
        <React.Fragment>
            <TopBar context={context} />
            <Loading open={loading} />

            <Snackbar
                open={error.active} autoHideDuration={10000} onClose={() => setError({ ...error, active: false })}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Paper>
                    <Alert severity="error"
                        onClose={() => setError({ ...error, active: false })}>{error.message}</Alert>
                </Paper>
            </Snackbar>

            <Container>
                <Stack spacing={2} style={{ marginTop: 20 }}>
                    <Databases context={context} user={user} />
                </Stack>
            </Container>
            <DatabasesMenu context={context} user={user} />
        </React.Fragment>
    );
}
