import React, { FC, useState } from "react";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import { Database, Delete, Details, Restore, User } from ".";
import { useRefresh } from "./useRefresh";
import { DashboardContext } from "../Dashboard";

interface ActionMenuProps {
    database: Database;
    context: DashboardContext;
    user: User;
}

export const ActionMenu: FC<ActionMenuProps> = ({ database: db, context, user }) => {
    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
    const useDetails = useState(false);
    const setDetailsOpen = useDetails[1];
    const useDelete = useState(false);
    const setDeleteOpen = useDelete[1];
    const useRestore = useState(false);
    const setRestoreOpen = useRestore[1];

    const refresh = useRefresh(db.id, context);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDetails = () => {
        setAnchorEl(null);
        setDetailsOpen(true);
    };

    const handleRefresh = () => {
        setAnchorEl(null);
        refresh();
    };

    const handleDelete = () => {
        setAnchorEl(null);
        setDeleteOpen(true);
    };

    const handleRestore = () => {
        setAnchorEl(null);
        setRestoreOpen(true);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleMenu}>
                <MoreHorizIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}>
                <MenuItem onClick={handleDetails}>Details...</MenuItem>
                <MenuItem onClick={handleRefresh}>Refresh</MenuItem>
                {user.isAdmin && <Box key="admin-btn">
                  <MenuItem onClick={handleDelete}>{db.status === 1 ? "Remove" : "Delete"}</MenuItem>
                  <MenuItem onClick={handleRestore} sx={{ display: db.status === 1 ? "visible" : "none" }}>Restore</MenuItem>
                </Box>}
            </Menu>
            <Details useOpen={useDetails} database={db} />
            <Delete useOpen={useDelete} database={db} context={context} />
            <Restore useOpen={useRestore} database={db} context={context} />
        </React.Fragment>
    );
}
