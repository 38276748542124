import React, { FC, useEffect, useState } from "react";
import { Paper, Table, TableBody, TableCell, TableRow, TablePagination } from "@mui/material";
import { Database, DatabaseRow, DatabasesTableHead, Order, User, useDatabases } from "src/Databases";
import { DashboardContext } from "src/Dashboard";
import { Title } from "src/Title";

interface DatabasesProps {
    context: DashboardContext;
    user: User;
}

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T) =>
    b[orderBy] < a[orderBy] ? -1 : b[orderBy] > a[orderBy] ? 1 : 0;

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string | boolean | Date | string[] },
    b: { [key in Key]: number | string | boolean | Date | string[] },
) => number {
    return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const Databases: FC<DatabasesProps> = ({ context, user }) => {
    const databases = useDatabases(context);
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<keyof Database>("name");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        setPage(0);
    }, [databases]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Database,
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Title>Databases</Title>
            <Table size="small">
            <DatabasesTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <TableBody>
                {databases.length === 0 ?
                    <TableRow><TableCell align="center" colSpan={7}>No data to display.</TableCell></TableRow> :
                    databases.sort(getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(db => <DatabaseRow key={db.id} database={db} context={context} user={user} />)}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={databases.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
