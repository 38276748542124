import { useCallback } from "react";
import { AppSettings } from "src/AppSettings";
import { DashboardContext } from "src/Dashboard";
import { useOktaAuth } from "@okta/okta-react";

export function useRestore(
  databaseId: string,
  context: DashboardContext
): () => void {
  const { oktaAuth } = useOktaAuth();
  return useCallback(() => {
    const fetchRestore = async () => {
      const setStale = context.useStale[1];
      const token = oktaAuth.getIdToken();
      try {
        const response = await fetch(
          `${AppSettings.apiHost}/api/v1/ui/databases/${databaseId}/restore`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              ...(token && { Authorization: `Bearer ${token}` }),
            },
          }
        );

        if (!response.ok)
          throw new Error(
            "An error occurred while restoring the database. " +
              `The response status is ${response.status} - ${response.statusText}.`
          );

        setStale(true);
      } catch (e: any) {
        context.setError({ active: true, message: e.message });
      }
    };

    fetchRestore();
  }, [databaseId, context, oktaAuth]);
}
