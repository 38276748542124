import { FC } from "react";
import { Backdrop, CircularProgress, Dialog, DialogContent, DialogContentText, Stack } from "@mui/material";

interface LoadingProps {
    open: boolean;
};

export const Loading: FC<LoadingProps> = ({ open }) => (
    <Dialog
        open={open}
        scroll="paper"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}>
            <DialogContent>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <CircularProgress />
                        <DialogContentText>Loading, please wait...</DialogContentText>
                    </Stack>
            </DialogContent>
    </Dialog>
);
