import React, { FC } from "react";
import {
    Backdrop,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Database, DatabaseStatus, DatabaseTypes, UUIDList } from "src/Databases";
import { Title } from "src/Title";


interface DetailsProps {
    database: Database;
    useOpen: [ boolean, (open: boolean) => void ];
}

export const Details: FC<DetailsProps> = ({ database: db, useOpen: [ open, setOpen ] }) => (
    <React.Fragment>
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={open}
            onClose={() => setOpen(false)}
            scroll="paper"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <DialogTitle>
                <Title>Database Details</Title>
                <IconButton onClick={() => setOpen(false)} sx={{ position: 'absolute', right: 8, top: 8, color: "grey" }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Table size="small" sx={{ marginBottom: 4 }}>
                    <TableBody>
                        <TableRow><TableCell>UUID</TableCell><TableCell>{db.id}</TableCell></TableRow>
                        <TableRow><TableCell>Version</TableCell><TableCell>{db.version}</TableCell></TableRow>
                        <TableRow><TableCell>Name</TableCell><TableCell>{db.name}</TableCell></TableRow>
                        <TableRow><TableCell>Status</TableCell><TableCell>{DatabaseStatus[db.status]}</TableCell></TableRow>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>{DatabaseTypes[db.type]}</TableCell>
                        </TableRow>
                        <TableRow><TableCell>Rave Url</TableCell><TableCell>{db.raveUrl}</TableCell></TableRow>
                        <TableRow>
                            <TableCell>Classic</TableCell>
                            <TableCell>{db.isClassic ? "Yes" : "No"}</TableCell>
                        </TableRow>
                        <TableRow><TableCell>SQL Server UUID</TableCell><TableCell>{db.sqlServerId}</TableCell></TableRow>
                        <TableRow><TableCell>SQL Server Name</TableCell><TableCell>{db.sqlServerName}</TableCell></TableRow>
                        <TableRow><TableCell>SQL Server Alias</TableCell><TableCell>{db.sqlServerAlias}</TableCell></TableRow>
                        <TableRow><TableCell>Connection String</TableCell><TableCell>{db.connectionString}</TableCell></TableRow>
                        <TableRow><TableCell>Created</TableCell><TableCell>{db.created.toLocaleString()}</TableCell></TableRow>
                        <TableRow><TableCell>Updated</TableCell><TableCell>{db.updated.toLocaleString()}</TableCell></TableRow>
                    </TableBody>
                </Table>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <UUIDList title="Study Group UUIDs" uuids={db.studyGroupUuids} />
                    </Grid>
                    <Grid item xs={4}>
                        <UUIDList title="Study UUIDs" uuids={db.studyUuids} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    </React.Fragment>
);
