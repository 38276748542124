import React, { FC } from "react";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import ReportIcon from "@mui/icons-material/Report";
import MailIcon from "@mui/icons-material/Mail";
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import { Avatar, Badge, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Popover, Tooltip } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { getUser } from "src/Helpers/common";
import { red } from "@mui/material/colors";

export const UserMenu: FC = () => {
  const { authState } = useOktaAuth();
  const user = getUser(authState);

  const powerUserRequestMessage = `If you need 'Power User' permission, please create people support ticket to add you to the '${user.powerUserGroup}' group.`;
  const powerUserText = "Power User";
  const readOnlyText = "Read Only";

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClick={handlePopoverOpen}
        badgeContent={
          <Tooltip title={user.isAdmin ? powerUserText : readOnlyText}>
            {user.isAdmin ? <AdminPanelSettingsIcon sx={{
              width: 18, height: 18, fill: "green", stroke: "white", strokeWidth: 1
            }} /> : <RemoveModeratorIcon sx={{
              width: 16, height: 16, fill: "red"
            }} />
            }
          </Tooltip>
        }
      >
        <Avatar>{user.initials}</Avatar>
      </Badge>

      <Popover
        id='user-popover'
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List dense={true} sx={{
          width: '100%', maxWidth: 250, bgcolor: 'background.paper'
        }} >
          <ListItem>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={user.name} />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary={user.email} />
          </ListItem>

          <Divider />

          <ListItem secondaryAction={
            !user.isAdmin && <Tooltip title={ powerUserRequestMessage }>
                <IconButton edge="end">
                <ReportIcon sx={{ color: red[500] }} />
                </IconButton>
              </Tooltip>
            }
          >
            {user.isAdmin ? (<>
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary={ powerUserText } />             
            </>) : (<>
                <ListItemIcon>
                  <RemoveModeratorIcon />
                </ListItemIcon>
                <ListItemText primary={ readOnlyText } />              
            </>)
              
            }
          </ListItem>
        </List>
        
      </Popover>
    </React.Fragment>
  );
};
