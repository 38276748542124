import React, { FC } from "react";
import { Collapse, Grid, IconButton, TableCell, TableRow } from "@mui/material";
import {
    KeyboardArrowUp as KeyboardArrowUpIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon
} from "@mui/icons-material";
import { ActionMenu, Database, DatabaseTypes, UUIDList, User } from "src/Databases";
import { DashboardContext } from "src/Dashboard";
import { grey } from "@mui/material/colors";

interface DatabaseRowProps {
    database: Database;
    context: DashboardContext;
    user: User;
}

export const DatabaseRow: FC<DatabaseRowProps> = ({ database: db, context, user }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{
                "& > *": {
                    borderBottom: "unset !important",
                    color: (db.status === 1 ? grey[500] : "inherit"),
                    backgroundColor: (db.status === 1 ? grey[50] : "inherit")
                }
            }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="left">{db.name}</TableCell>
                <TableCell align="center">{DatabaseTypes[db.type]}</TableCell>
                <TableCell align="center">{db.version}</TableCell>
                <TableCell align="left">{db.raveUrl}</TableCell>
                <TableCell align="center">{db.isClassic ? "Yes" : "No"}</TableCell>
                <TableCell align="center"><ActionMenu database={db} context={context} user={user} /></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <UUIDList title="Study Group UUIDs" uuids={db.studyGroupUuids} />
                            </Grid>
                            <Grid item xs={4}>
                                <UUIDList title="Study UUIDs" uuids={db.studyUuids} />
                            </Grid>
                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};
