import { Routes, Route, useNavigate } from "react-router-dom";
import { Dashboard } from "src/Dashboard";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { oktaAuthConfig } from "src/Authentication/OktaSettings";
import { LoginCallback } from "@okta/okta-react";
import { RequiredAuth } from "src/Authentication/SecureRoute";
import { Loading } from "src/Dashboard/Loading";

const AppRoutes = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <Security
      oktaAuth={new OktaAuth(oktaAuthConfig)}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Routes>
        <Route
          path="/ui/login/callback"
          element={<LoginCallback loadingElement={<Loading open />} />}
        />
        <Route path="/ui/dashboard" element={<RequiredAuth />}>
          <Route path="" element={<Dashboard />} />
        </Route>
        <Route path="/ui" element={<RequiredAuth />}>
          <Route path="" element={<Dashboard />} />
        </Route>
      </Routes>
    </Security>
  );
};
export default AppRoutes;
