import { useCallback } from "react";
import { AppSettings } from "src/AppSettings";
import { DashboardContext } from "src/Dashboard";
import { useOktaAuth } from "@okta/okta-react";

export function useAdd(
  context: DashboardContext
): (studyGroupUuid: string, connectionString: string) => void {
  const { oktaAuth } = useOktaAuth();

  return useCallback(
    (studyGroupUuid: string, connectionString: string) => {
      const fetchAdd = async () => {
        const setStale = context.useStale[1];
        const token = oktaAuth.getIdToken();
        try {
          const response = await fetch(
            `${AppSettings.apiHost}/api/v1/ui/databases`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                ...(token && { Authorization: `Bearer ${token}` }),
              },
              body: JSON.stringify({
                study_group_uuid: studyGroupUuid,
                connection_string: connectionString,
              }),
            }
          );

          if (!response.ok)
            throw new Error(
              "An error occurred while adding new database. " +
                `The response status is ${response.status} - ${response.statusText}.`
            );

          setStale(true);
        } catch (e: any) {
          context.setError({ active: true, message: e.message });
        }
      };

      fetchAdd();
    },
    [context, oktaAuth]
  );
}
