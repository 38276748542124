import { FC } from "react";
import { Typography } from "@mui/material";

interface TitleProps {
    children?: React.ReactNode;
}

export const Title: FC<TitleProps> = ({ children }) => (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {children}
    </Typography>
);
